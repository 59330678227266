import { PortableText } from '@portabletext/react'
import { getClient } from '../lib/sanity.client'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { LOCAL_SLUGS } from '../lib/constants'
import { NextSeo } from 'next-seo'
import { useEffect, useState } from 'react'
import { PORTABLE_COMPONENTS } from '../lib/helper/portableTextHelpers'
import PageHead from '../components/shared/PageHead'

const getLocaleSlug = (locale: AppLocale, slug: string) => {
  return LOCAL_SLUGS.find((s) => s[locale] === slug)
}

const useLegalDocument = (slug: string) => {
  const { t, i18n } = useTranslation('common')
  const localeSlug = getLocaleSlug(i18n.language as AppLocale, slug)
  const [localizedDocument, setLocalizedDocument] =
    useState<LegalDocument | null>(null)

  useEffect(() => {
    const fetchDocument = async () => {
      if (!localeSlug) {
        const client = getClient()
        const query = `
          *[_type in ["imprint", "privacy", "terms"] && slug.${i18n.language}.current == "${slug}"][0] {
            _id,
            _type,
            "content": content.${i18n.language},
            "meta_title": meta_title.${i18n.language},
            "meta_description": meta_description.${i18n.language}
          }
        `
        const document = await client.fetch(query)
        setLocalizedDocument(document)
      } else {
        setLocalizedDocument({
          meta_title: t(localeSlug.en + '-meta-title'),
          meta_description: t(localeSlug.en + '-meta-description'),
        })
      }
    }

    fetchDocument()
  }, [i18n.language, slug, t, localeSlug])

  return { localizedDocument, localeSlug }
}

const LegalPage = ({ slug }: { slug: string }) => {
  const { localizedDocument, localeSlug } = useLegalDocument(slug)

  if (!localizedDocument && !localeSlug) {
    return null
  }
  return (
    <div>
      <PageHead />
      {localizedDocument && (
        <NextSeo
          title={localizedDocument.meta_title}
          description={localizedDocument.meta_description}
        />
      )}
      {localeSlug ? (
        localeSlug.component()
      ) : (
        <div className="mt-6 gyg-widget-copy">
          <PortableText
            value={localizedDocument.content}
            components={PORTABLE_COMPONENTS}
          />
        </div>
      )}
    </div>
  )
}

export default LegalPage

export const getServerSideProps = async (ctx: any) => {
  const { locale, params } = ctx
  const { any } = params
  const translations = await serverSideTranslations(locale, ['common'])

  return {
    props: { ...translations, slug: any },
  }
}
