import { FAQPageJsonLd, ProductJsonLd, WebPageJsonLd } from 'next-seo'
import { getHref, replaceWildcards } from '../../lib/helper/helper'
import { urlForImage } from '../../lib/sanity.image'
import { getPrice } from '../../lib/helper/price'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'

type Props = {
  item?:
    | Country
    | State
    | Region
    | Category
    | MajorLocation
    | ReducedMajorLocation
    | Product
  settings?: Settings
  faqs?: FAQ[]
}

type ItemWithNameAndDescription = {
  name?: string
  h1?: string
  nameDescription?: string
  number_activities?: number
  number_locations?: number
}

const PageSchema: React.FC<Props> = ({ item, settings, faqs }) => {
  const { i18n } = useTranslation('common')

  if (item && typeof item === 'object') {
    const itemWithNameAndDescription = item as ItemWithNameAndDescription

    if (typeof itemWithNameAndDescription.name === 'string') {
      itemWithNameAndDescription.name = replaceWildcards(
        itemWithNameAndDescription.name,
        {
          number_activities: itemWithNameAndDescription.number_activities,
          number_locations: itemWithNameAndDescription.number_locations,
        },
      )
    }

    if (typeof itemWithNameAndDescription.h1 === 'string') {
      itemWithNameAndDescription.h1 = replaceWildcards(
        itemWithNameAndDescription.h1,
        {
          number_activities: itemWithNameAndDescription.number_activities,
          number_locations: itemWithNameAndDescription.number_locations,
        },
      )
    }

    if (typeof itemWithNameAndDescription.nameDescription === 'string') {
      itemWithNameAndDescription.nameDescription = replaceWildcards(
        itemWithNameAndDescription.nameDescription,
        {
          number_activities: itemWithNameAndDescription.number_activities,
          number_locations: itemWithNameAndDescription.number_locations,
        },
      )
    }
  }

  const renderWebPageJsonLd = () => {
    if (!settings) return null

    const { banner, h1, h2, description } = settings

    return (
      <WebPageJsonLd
        name={replaceWildcards(h1)}
        description={replaceWildcards(h2)}
        id="https://sight2sight.com"
        publisher={{
          name: 'SIGHT2SIGHT',
          logo: {
            url: 'https://www.sight2sight.com/assets/logo.png',
            caption: 'SIGHT2SIGHT Logo',
          },
          description: replaceWildcards(description),
        }}
        image={
          banner?.image
            ? {
                url: urlForImage(banner?.image)?.url(),
                caption: replaceWildcards(
                  (banner?.image.alt || {})['de' as AppLocale],
                ),
                alternateName: replaceWildcards(
                  (banner?.image.alt || {})['de' as AppLocale],
                ),
              }
            : {}
        }
      />
    )
  }

  const renderProductJsonLd = (product: Product) => {
    const {
      h1,
      image,
      meta_description,
      product_badge,
      product_class,
      category,
      reviews,
    } = product
    const { price, currency } = getPrice(
      product.price_old,
      i18n.language as AppLocale,
      true,
    )

    return (
      <ProductJsonLd
        productName={h1}
        images={[image ? urlForImage(image.image)?.url() : null]}
        description={meta_description ? replaceWildcards(meta_description) : ''}
        brand="SIGHT 2 SIGHT"
        isFamilyFriendly={
          product_badge === 'family_friendly' ? true : undefined
        }
        category={[
          ...(product_class?.text ? [product_class.text] : []),
          ...(category || []).map((c) => c.name),
        ].join(', ')}
        aggregateRating={{
          ratingValue: reviews?.stars?.toString(),
          reviewCount: reviews?.numberOfReviews?.toString(),
          bestRating: '5',
        }}
        offers={[
          {
            price: price,
            priceCurrency: currency,
            availability: 'https://schema.org/InStock',
            validFrom: new Date().toISOString().split('T')[0],
            priceValidUntil: '2099-12-31',
          },
        ]}
      />
    )
  }

  const renderAggregateRatingSchema = (
    item: Category | State | Country | Region | MajorLocation,
  ) => {
    if (
      !item?.reviews ||
      item.reviews.stars <= 0 ||
      item.reviews.numberOfReviews <= 0
    )
      return null

    return (
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'CreativeWorkSeries',
              name: item?.h1 || item?.name,
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: item.reviews.stars.toString(),
                ratingCount: item.reviews.numberOfReviews.toString(),
                bestRating: 5,
              },
            }),
          }}
        />
      </Head>
    )
  }

  const renderPlaceSchema = (
    item: State | Country | Region | MajorLocation,
  ) => {
    const { name, nameDescription, coverImage } = item

    return (
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Place',
              name: replaceWildcards(name),
              description: replaceWildcards(nameDescription),
              image: coverImage?.image
                ? urlForImage(coverImage?.image)?.url()
                : '',
              url: `https://sight2sight.com${i18n.language === 'en' ? '' : '/' + i18n.language}${getHref(item, i18n.language as AppLocale)}`,
            }),
          }}
        />
      </Head>
    )
  }

  const renderFAQPageJsonLd = () => {
    if (!faqs || faqs.length === 0) return null

    return (
      <FAQPageJsonLd
        mainEntity={faqs.map((faq) => ({
          questionName: faq.faq_question,
          acceptedAnswerText: faq.faq_answer,
        }))}
      />
    )
  }

  if (!item && settings) {
    return (
      <>
        {renderWebPageJsonLd()}
        {renderFAQPageJsonLd()}
      </>
    )
  }

  switch (item?._type) {
    case 'product':
      return (
        <>
          {renderProductJsonLd(item as Product)}
          {renderFAQPageJsonLd()}
        </>
      )
    case 'category':
      return (
        <>
          {/* {renderCollectionPageJsonLd(item as Category)} */}
          {renderAggregateRatingSchema(item as Category)}
          {renderFAQPageJsonLd()}
        </>
      )
    case 'state':
    case 'country':
    case 'region':
    case 'city':
      return (
        <>
          {renderPlaceSchema(item as State | Country | Region | MajorLocation)}
          {renderAggregateRatingSchema(
            item as State | Country | Region | MajorLocation,
          )}
          {renderFAQPageJsonLd()}
        </>
      )
    default:
      return null
  }
}

export default PageSchema
